<template>
  <div class="rule">
    <div class="navBar">
      <div @click="backPage">
        <svg-icon class="navArrow" iconClass="navArrow" />
      </div>
      <div class="navTitle">签到规则</div>
      <!--            <div class="rightBtn" @click="$router.push('/signRule')">规则</div>-->
      <!--            <div class="rightBtn" @click="publish">发布</div>-->
    </div>
    <div class="main">
      <p>规则说明：</p>
      <p>（1）积分使用过程中不找零、不兑现、不开发票，不可转移至其他账户。</p>
      <p>
        （2）使用积分进行兑换，兑换申请一经提交,
        一律不能取消（除奖品缺货等特殊情况）。
      </p>
      <p>（3）奖品会通过快递，15个工作日内完成发货。</p>
      <p>（4）因地址不对造成无法收到快递，平台不会补寄奖品。</p>
      <p>（5）所有发货奖品包邮，不收取兑换人任何费用。</p>
      <p>（6）兑换的奖品不可退换货。</p>
      <p>（7）平台保留最终解释权。</p>
      <p>（8）积分有效期为一年，过期自动清理。</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "rule",
  methods: {
    // 返回
    backPage() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
.rule {
  height: 100%;
  .navBar {
    height: 44px;
    padding: 0 12px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    //border-bottom: 1px solid #e6e6e6;

    .navArrow {
      width: 17px;
      height: 17px;
    }

    .navTitle {
      flex: 2;
      text-align: center;
      font-size: 18px;
      color: #333333;
      //padding-left: 20px;
    }

    .rightBtn {
      font-size: 14px;
      color: #666666;
    }
  }

  .main {
    color: #333;
    font-size: 12px;
    padding: 18px 10px;
    box-sizing: border-box;
    line-height: 180%;
    background: #f5f5f5;
    height: 100%;
  }
}
</style>
